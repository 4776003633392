@import "default";
@import "var";
@import "util";

body {
	width: 100%;
	background: #fff;
	font-size: 15px;
	color: #222;
	text-align: center;
	letter-spacing: 0.06em;

	@include max_screen($bp_sp) {
		padding-top: 50px;
	}
}

img {
	max-width: 100%;
	height: auto;
}

// global-header ---------------------------------------------------------
.global-header {
	width: 100%;
	padding: 0 20px;
	box-sizing: border-box;
	height: 188px;
	margin: 0 auto;
	position: relative;
	z-index: 1000;
	border-bottom: 1px solid #ddd;

	@at-root .index & {
		border: none;
	}

	&_logo {
		padding: 50px 0 0;
		margin-bottom: 50px;
	}

	@include max_screen($bp_sp) {
		height: 50px;
		position: fixed;
		top: 0;
		left: 0;
		background: #fff;

		&_logo {
			width: 130px;
			padding: 0;
			position: absolute;
			top: 15px;
			left: 10px;
		}
	}

	&_navi {
		width: 100%;
		padding-left: 10px;

		&_open-btn {
			display: none;
		}

		&_header {
			display: none;
		}

		&_sub {
			display: none;
		}

		@include max_screen($bp_sp) {
			position: fixed;
			top: 0;
			right: -100%;
			//left: 0;
			background: #fff;
			padding: 0;
			height: 100%;
			padding-bottom: 45px;
			overflow-y: auto;
			-webkit-overflow-scrolling: touch;
			@include transition(.2);
			box-sizing: border-box;

			&.show {
				right: 0;
			}

			&_header {
				width: 100%;
				height: 50px;
				border-bottom: 1px solid #ddd;
				position: relative;
				display: block;

				&_ttl {
					font-size: 16px;
					font-weight: bold;
					color: $brown_color;
					position: absolute;
					top: 18px;
					left: 10px;
					line-height: 1;
					letter-spacing: 0.12em;
				}
			}

			&_open-btn {
				width: 20px;
				height: 16px;
				position: absolute;
				top: 17px;
				right: 15px;
				cursor: pointer;
				box-sizing: border-box;
				display: block;

				span {
					content: "";
					width: 20px;
					height: 2px;
					background: $brown_color;
					position: absolute;
					top: 7px;
					left: 0;
				}

				&:before {
					content: "";
					width: 20px;
					height: 2px;
					background: $brown_color;
					position: absolute;
					top: 0;
					left: 0;
				}

				&:after {
					content: "";
					width: 20px;
					height: 2px;
					background: $brown_color;
					position: absolute;
					bottom: 0;
					left: 0;
				}
			}

			&_close-btn {
				width: 20px;
				height: 16px;
				position: absolute;
				top: 17px;
				right: 15px;
				cursor: pointer;
				box-sizing: border-box;

				&:before {
					content: "";
					width: 20px;
					height: 2px;
					background: $brown_color;
					position: absolute;
					top: 7px;
					left: 0;
					transform:rotate(45deg);
				}

				&:after {
					content: "";
					width: 20px;
					height: 2px;
					background: $brown_color;
					position: absolute;
					bottom: 7px;
					left: 0;
					transform:rotate(-45deg);
				}
			}

			&_main {
				margin-bottom: 42px;
			}

			&_sub {
				width: 100%;
				display: block;
				text-align: left;
				padding-bottom: 43px;

				&_ttl {
					font-size: 15px;
					font-weight: bold;
					padding-left: 10px;
					margin-bottom: 9px;
				}

				&_list {
					width: 100%;
					overflow: hidden;
					border-top: 1px solid #ddd;

					li {
						width: 50%;
						float: left;
						border: 1px solid #ddd;
						border-width: 0 1px 1px 0;
						box-sizing: border-box;
						font-size: 14px;
						@include txt_link_color;

						&:nth-child(2n) {
							border-right: none;
						}

						a {
							display: flex;
							height: 50px;
							line-height: 1.2;
							padding: 0 10px;
							align-items: center;
							vertical-align: middle;
						}
					}
				}
			}
		}

		&_item {
			display: inline-block;
			position: relative;
			margin-right: 68px;
			@include txt_link_color;
			font-weight: bold;
			font-size: 16px;

			@include min_screen(768px) {
				&:hover > a {
					color: #bdb198;
				}
			}

			&:last-child {
				margin-right: 0;
			}

			> a {
				display: inline-block;
				padding: 10px 0;
			}

			&:before {
				content: "";
				position: absolute;
				background-repeat: no-repeat;
				background-size: 100% auto;
			}

			@include max_screen($bp_tab) {
				font-size: 14px;
				margin-right: 48px;

				&:last-child {
					margin-right: 0;
				}

				&:before {
					margin-top: -1px;
				}
			}

			@include max_screen($bp_sp) {
				display: block;
				border-bottom: 1px solid #ddd;
				margin: 0;
				text-align: left;

				a {
					display: block;
					width: 100%;
					height: 50px;
					padding: 0 0 0 40px;
					line-height: 50px;
					box-sizing: border-box;
				}

				&:after {
					content: "";
					width: 19px;
					height: 19px;
					background: url("/wp-content/themes/blog/assets/img/common/ico_navi_open.png") no-repeat;
					background-size: 100% auto;
					position: absolute;
					top:15px;
					right: 15px;
				}

				&.open:after {
					background-image: url("/wp-content/themes/blog/assets/img/common/ico_navi_close.png");
				}

				&.open .inner_navi {
					max-height: 500px;
				}
			}

			&:nth-child(1):before {
				width: 16px;
				height: 14px;
				top: 14px;
				left: -22px;
				background-image: url("/wp-content/themes/blog/assets/img/common/ico_navi1.png");
			}

			&:nth-child(2):before {
				width: 23px;
				height: 12px;
				top: 16px;
				left: -28px;
				background-image: url("/wp-content/themes/blog/assets/img/common/ico_navi2.png");
			}

			&:nth-child(3):before {
				width: 17px;
				height: 17px;
				top: 13px;
				left: -24px;
				background-image: url("/wp-content/themes/blog/assets/img/common/ico_navi3.png");
			}

			&:nth-child(4):before {
				width: 12px;
				height: 17px;
				top: 11px;
				left: -20px;
				background-image: url("/wp-content/themes/blog/assets/img/common/ico_navi4.png");
			}

			&:nth-child(5):before {
				width: 18px;
				height: 12px;
				top: 15px;
				left: -24px;
				background-image: url("/wp-content/themes/blog/assets/img/common/ico_navi5.png");
			}

			&:nth-child(6):before {
				width: 16px;
				height: 14px;
				top: 15px;
				left: -24px;
				background-image: url("/wp-content/themes/blog/assets/img/common/ico_navi6.png");
			}

			&:nth-child(7):before {
				width: 14px;
				height: 15px;
				top: 14px;
				left: -19px;
				background-image: url("/wp-content/themes/blog/assets/img/common/ico_navi7.png");
			}

			@include max_screen($bp_sp) {
				&:nth-child(1):before {
					width: 16px;
					height: 14px;
					top: 18px;
					left: 17px;
				}

				&:nth-child(2):before {
					width: 23px;
					height: 12px;
					top: 20px;
					left: 12px;
				}

				&:nth-child(3):before {
					width: 17px;
					height: 17px;
					top: 16px;
					left: 15px;
				}

				&:nth-child(4):before {
					width: 12px;
					height: 17px;
					top: 14px;
					left: 18px;
				}

				&:nth-child(5):before {
					width: 18px;
					height: 12px;
					top: 19px;
					left: 15px;
				}

				&:nth-child(6):before {
					width: 16px;
					height: 14px;
					top: 18px;
					left: 16px;
				}

				&:nth-child(7):before {
					width: 14px;
					height: 15px;
					top: 17px;
					left: 17px;
				}
			}

			.inner_navi {
				width: 200px;
				position: absolute;
				top: 40px;
				left: -40px;
				background: #fff;
				padding: 15px 0 15px 40px;
				text-align: left;
				box-sizing: border-box;
				opacity: 0;
				visibility: hidden;
				margin-top: -10px;
				@include transition(.25);

				li {
					padding: 15px 0;
					line-height: 1;
				}

				@include max_screen($bp_tab) {

					&.last {
						left: auto;
						right: 0;
					}
				}

				@include max_screen($bp_sp) {
					width: 100%;
					max-height: 0;
					background: #f4f4f4;
					position: static;
					padding: 0;
					opacity: 1;
					visibility: visible;
					margin: 0;
					overflow: hidden;
					@include transition(.3, max-height);

					li {
						height: 50px;
						border-top: 1px solid #ddd;
						padding: 0;
					}
				}
			}

			@include min_screen(768px) {
				&:hover .inner_navi {
					opacity: 1;
					visibility: visible;
					margin-top: 0;
				}
			}
		}

	}
}

// blog-catch ---------------------------------------------------------
.blog-catch {
	width: 100%;
	background-size: cover;
	background-position: center center;
	@include txt_link_color;
	a:visited {
		color: inherit;
	}

	&_inner {
		width: 100%;
		max-width: 1040px;
		padding: 0 20px;
		box-sizing: border-box;
		height: 550px;
		margin: 0 auto;
		position: relative;

		@include max_screen($bp_sp) {
			height: 228px;
		}
	}

	&_ttl {
		font-weight: bold;
		text-align: left;
		position: absolute;
		bottom: 47px;
		left:0;

		span {
			display: inline-block;
			background: #fff;
			line-height: 1;
			font-size: 26px;
			padding: 10px 13px 8px;
			margin-right: 20px;
			border-radius: 2px;
			margin-bottom: 3px;
		}

		@include max_screen($bp_sp) {
			bottom: 20px;
			left: 10px;

			span {
				padding: 6px 9px 5px;
				font-size: 17px;
			}
		}
	}
}

// contents ---------------------------------------------------------
.contents {
	width: 100%;
	max-width: 1040px;
	padding: 70px 20px 0;
	box-sizing: border-box;
	margin: 0 auto;
	overflow: hidden;
	text-align: left;

	&.archive_page,
	&.category_page,
	&.single_page {
		padding-top: 60px;
	}

	.main-col {
		width: 72%;
		float: left;
	}

	.side-col {
		width: 23%;
		float: right;

		&_ttl {
			font-weight: bold;
			color: $brown_color;
			font-size: 16px;
			padding-bottom: 10px;
			border-bottom: 1px solid #ddd;
			margin-bottom: 16px;
		}
	}

	@include max_screen($bp_sp) {
		padding: 30px 0 0;

		.archive_page,
		&.category_page,
		&.single_page {
			padding-top: 30px;
		}

		.main-col {
			width: 100%;
			float: none;
			padding: 0 10px;
			box-sizing: border-box;
			margin-bottom: 50px;
		}

		.side-col {
			width: 100%;
			float: none;
			padding: 0 10px;
			box-sizing: border-box;

			&_ttl {
				color: $brown_color;
				font-size: 17px;
				padding: 13px 0 12px;
				border-bottom: none;
				margin-bottom: 22px;
				background: #eee;
				text-align: center;
				border-radius: 2px;
			}
		}
	}
}

// article-list ---------------------------------------------------------
.article-list {

	&_item {
		width: 100%;
		overflow: hidden;
		padding-bottom: 40px;
		margin-bottom: 40px;
		border-bottom: 1px solid #ddd;

		&:last-child {
			border-bottom: none;
			margin-bottom: 0;
			padding-bottom: 0;
		}

		.eyecatch {
			width: 29%;
			padding-top: 29%;
			float: left;
			border-radius: 2px;
			overflow: hidden;
			position: relative;

			img {
				width: 100%;
				backface-visibility: hidden;
				position: absolute;
				top: 0;
				left: 0;
			}
		}

		@include max_screen($bp_sp) {
			padding-bottom: 25px;
			margin-bottom: 25px;

			.eyecatch {
				border-radius: 4px;
			}
		}

		.detail {
			width: 66%;
			float: right;
			@include txt_link_color;

			.ttl {
				font-size: 20px;
				font-weight: bold;
				letter-spacing: 0.08em;
				line-height: 1.5;

				a {
					display: block;
				}
			}

			.meta {
				font-size: 13px;
				color: #a7997c;
				padding: 10px 0 18px;

				&_cat {
					display: inline-block;
					margin-left: 26px;

					li {
						display: inline;

						&:after {
							content: " / ";
						}

						&:last-child:after {
							display: none;
						}
					}
				}

				a {
					color: inherit;

					&:hover {
						color: $brown_color;
					}
				}
			}

			.excerpt {
				line-height: 1.6;
				letter-spacing: 0.1em;
				text-align: left;

				p,a {
					display: inline;
				}
			}

			@include max_screen($bp_sp) {

				.ttl {
					font-size: 17px;
				}

				.meta {
					padding: 4px 0 0;

					&_cat {
						display: none;
					}
				}

				.excerpt {
					display: none;
				}
			}
		}

	}
}

// pager ---------------------------------------------------------
.pager {
	width: 100%;
	text-align: center;
	padding-top: 80px;
	@include txt_link_color;

	a, span {
		display: inline-block;;
		padding: 3px 8px;
		border: 1px solid $brown_color;
		margin: 0 3px;
		text-align: center;

		&.current {
			font-weight:bold;
			background: $brown_color;
			color: #fff;
		}
	}

	@include max_screen($bp_sp) {
		padding-top: 40px;
		font-size: 13px;

		a, span {
			display: inline-block;
			padding: 5px 10px 4px;
			border: 1px solid $brown_color;
			margin: 0 3px;
			text-align: center;

			&.pages {
				display: none;;
			}

			&.nextpostslink,
			&.previouspostslink {
				padding: 5px 5px 4px;
				letter-spacing: 0.02em;
			}
		}
	}
}

// about-btn ---------------------------------------------------------
.about-btn {
	width: 100%;
	margin-bottom: 45px;
	@include btn_link_color;

	a {
		display: block;
		padding: 20px 30px;
		line-height: 1;
		font-weight: bold;
		font-size: 16px;
		letter-spacing: 0.02em;
		border-radius: 2px;

		span {
			padding-right: 17px;
			background: url("/wp-content/themes/blog/assets/img/common/ico_arw-white.png") no-repeat right center;
			background-size: 6px auto;
		}
	}

	@include max_screen($bp_sp) {
		display: none;
	}
}

// ranking ---------------------------------------------------------
.side-col_ranking {
	margin-bottom: 54px;

	.wpp-list {
		li {
			list-style: decimal;
			font-weight: bold;
			margin-left: 1.5em;
			margin-bottom: 15px;
			line-height: 1.4;
			@include txt_link_color;

			&:last-child {
				margin-bottom: 0;
			}

			a {
				font-weight: normal;
			}
		}
	}

	@include max_screen($bp_sp) {
		margin-bottom: 50px;
	}
}

// category ---------------------------------------------------------
.side-col_category {
	margin-bottom: 54px;

	&:last-of-type {
		margin-bottom: 60px;
	}

	&_list {
		li {
			margin-bottom: 13px;
			line-height: 1.4;
			@include txt_link_color;
			padding-left: 13px;
			margin-left: 7px;
			background: url("/wp-content/themes/blog/assets/img/common/ico_arw-s.png") no-repeat left center;

			&:last-child {
				margin-bottom: 0;
			}
		}
	}

	@include max_screen($bp_sp) {
		display: none;
	}
}

// search-form ---------------------------------------------------------
.search-form {
	width: 100%;
	position: relative;

	.search-field {
		width: 100%;
		height: 34px;
		background: #eee;
		border-radius: 2px;
		line-height: 34px;
		border: none;
		padding: 0 33px 0 14px;
	}

	.search-submit {
		position: absolute;
		top: 9px;
		right: 10px;
		width: 15px;
		height: 16px;
		background: url("/wp-content/themes/blog/assets/img/common/ico_search.png") no-repeat;
		background-size: 100% auto;
		border: none;
		font-family: Avenir, "Open Sans", -apple-system, BlinkMacSystemFont, "游ゴシック体", YuGothic, "游ゴシック Medium", ZaneYuGothicM, Meiryo, sans-serif;
	}

	@include max_screen($bp_sp) {
		padding: 0 10px;
		box-sizing: border-box;

		.search-field {
			height: 45px;
			line-height: 45px;
			font-size: 16px;
		}

		.search-submit {
			top: 12px;
			right: 20px;
			width: 22px;
			height: 23px;
		}
	}
}

// bnr ---------------------------------------------------------
.bnr {
	margin-top: 30px;

	img {
		width: 100%;
	}

	@include max_screen($bp_sp) {
		padding: 0 10px;
		margin-top: 0;
		margin-bottom: 25px;

		&:last-child {
			margin-bottom: 0;
		}
	}
}

// global-footer ---------------------------------------------------------
.global-footer {
	width: 100%;
	text-align: center;
	font-family: "Helvetica Neue", helvetica, arial;
	padding-top: 100px;

	&_sns {
		margin-bottom: 24px;
		color: $brown_color;

		dt {
			font-size: 16px;
			letter-spacing: 0.2em;
			display: inline-block;
			position: relative;
			top: -2px;
		}

		dd {
			display: inline-block;
			margin-left: 18px;
		}
	}

	&_copyright {
		color: #fff;
		font-size: 10px;
		letter-spacing: 0.2em;
		background: $brown_color;
		padding: 12px 0 10px;
		line-height: 1;
	}

	@include max_screen($bp_sp) {
		padding-top: 50px;

		&_sns {
			margin-bottom: 20px;

			dt {
				font-size: 10px;
			}

			dd {
				width: 25px;
				margin-left: 16px;
			}
		}

		&_copyright {
			font-size: 9px;
			padding: 19px 0;
		}
	}
}

// entry ---------------------------------------------------------
.entry {
	width: 100%;
	overflow: hidden;
	@include txt_link_color;
	padding-bottom: 20px;
	margin-bottom: 30px;
	border-bottom: 1px solid #ddd;

	a,
	a:link,
	a:visited {
		color: #24959c;
	}

	@include min_screen(768px) {
		a:hover {
			color: #bdb198;
		}
	}

	&_ttl {
		font-size: 28px;
		font-weight: bold;
		line-height: 1.5;
	}

	&_meta {
		font-size: 13px;
		color: #a7997c;
		padding: 16px 0 58px;

		&_cat {
			display: inline-block;
			margin-left: 26px;

			li {
				display: inline;

				&:after {
					content: " / ";
				}

				&:last-child:after {
					display: none;
				}
			}
		}

		a {
			color: #a7997c;

			&:link,
			&:visited {
				color: #a7997c;
			}

			&:hover {
				color: $brown_color;
			}
		}
	}

	@include max_screen($bp_sp) {

		&_ttl {
			font-size: 18px;
		}

		&_meta {
			padding: 15px 0 40px;
		}
	}

	&_body {
		font-size: 15px;
		letter-spacing: 0.12em;
		line-height: 1.6;
		margin-bottom: 70px;

		p {
			margin-bottom: 1.5em;
		}

		h2 {
			font-size: 26px;
			line-height: 1.4;
			margin-bottom: 0.8em;
			font-weight: bold;
		}

		h3 {
			font-size: 22px;
			line-height: 1.4;
			font-weight: bold;
		}

		h4 {
			font-size: 18px;
			line-height: 1.4;
			font-weight: bold;
		}

		@include max_screen($bp_sp) {
			font-size: 14px;
			margin-bottom: 35px;

			h2 {
				font-size: 18px;
			}

			h3 {
				font-size: 16px;
			}

			h4 {
				font-size: 15px;
			}
		}

		table {
			td,
			th {
				border: 1px solid #ccc;
				padding: 10px;
			}

			th {
				background: #f4f4f4;
				font-weight: bold;
				text-align: center;
			}
		}

		ul {
			margin-bottom: 1.5em;

			li {
				padding-left: 25px;
				position: relative;
				line-height: 1.4;
				margin-bottom: 10px;
				&:last-child {
					margin-bottom: 0;
				}

				&:before {
					content: "";
					width: 6px;
					height: 6px;
					background: #a7a7a7;
					border-radius: 50%;
					display: block;
					position: absolute;
					top: 8px;
					left: 9px;
				}
			}
		}
	}

	// staff
	&_staff {
		width: 100%;
		border: 3px solid #ddd;
		box-sizing: border-box;
		padding: 38px 38px 30px;
		position: relative;
		margin-bottom: 60px;

		&_ttl {
			width: 100%;
			font-size: 16px;
			font-weight: bold;
			position: absolute;
			top: -12px;
			left:0;
			right: 0;
			text-align: center;

			span {
				display: inline-block;
				padding: 0 5px;
				background: #fff;
			}
		}
		
		&_photo {
			width: 120px;
			display: table-cell;
			text-align: center;
		}

		&_img {
			width: 120px;
			height: 120px;
			border-radius: 50%;
			overflow: hidden;
			margin-bottom: 12px;
		}

		&_name {
			font-size: 13px;
			line-height: 1;
		}

		&_desc {
			display: table-cell;
			font-size: 15px;
			line-height: 1.6;
			vertical-align: middle;
			padding-left: 40px;
		}

		@include max_screen($bp_sp) {
			padding: 30px 15px 15px;
			margin-bottom: 50px;

			&_photo {
				width: 100%;
				display: block;
				margin-bottom: 20px;
			}

			&_img {
				width: 90px;
				height: 90px;
				margin: 0 auto 12px;
			}

			&_name {
				font-size: 14px;
			}

			&_desc {
				display: block;
				font-size: 14px;
				padding: 0;
			}
		}
	}

	//sns
	&_sns {
		width: 100%;

		&_item {
			overflow: hidden;
			display: inline-block;
			line-height: 1;
			vertical-align: top;
		}

		@include max_screen($bp_sp) {
			margin-top: -10px;
			overflow: hidden;

			&_item {
				margin-top: 10px;
				margin-right: 10px;
			}
		}
	}
}

// single-pager ---------------------------------------------------------
.single-pager {
	width: 100%;
	overflow: hidden;

	li {
		@include txt_link_color;
		width: 45%;
		font-size: 13px;

		&.prev {
			float: left;
			text-align: left;
			padding-left: 10px;
			background: url("/wp-content/themes/blog/assets/img/common/ico_arw_left-s.png") no-repeat left center;
		}

		&.next {
			float: right;
			text-align: right;
			padding-right: 10px;
			background: url("/wp-content/themes/blog/assets/img/common/ico_arw-s.png") no-repeat right center;
		}
	}
}


// related-posts ---------------------------------------------------------
.related-posts {
	padding-top: 40px;

	&_ttl {
		width: 100%;
		background: #eee;
		font-size: 20px;
		font-weight: bold;
		padding: 0 20px;
		line-height: 57px;
		border-radius: 2px;
		margin-bottom: 40px;
		box-sizing: border-box;
	}

	@include max_screen($bp_sp) {

		&_ttl {
			color: $brown_color;
			font-size: 16px;
			padding: 13px 0 12px;
			margin-bottom: 22px;
			background: #eee;
			text-align: center;
			line-height: 1;
		}
	}
}