$bp_tab: 999px;
$bp_sp: 767px;

$brown_color: #33201f;

// ---------------------------------
@mixin txt_link_color {
	a,
	a:link,
	a:visited {
		color: $brown_color;
		text-decoration: none;
		@include transition(.2, color);
	}

	@include min_screen(768px) {
		a:hover {
			color: #bdb198;
		}
	}
}

@mixin btn_link_color {
	a,
	a:link,
	a:visited {
		color: #fff;
		background-color: #bdb198;
		text-decoration: none;
		opacity: 1;
		@include transition(.2, opacity);
	}

	@include min_screen(768px) {
		a:hover {
			opacity: 0.7;
		}
	}
}

@mixin font_en {
	font-family: 'Cormorant Garamond', serif;
	font-weight: 300;
}

@mixin font_jp {
	font-family: "Yu Mincho", "YuMincho", serif;
}

@mixin cf {
    &::after {
        content: "";
        display: block;
        clear: both;
    }
}

@mixin placeholderColor($color) {
	&:placeholder-shown {
		color: $color;
	}
	&::-webkit-input-placeholder {
		color:$color;
	}
	&:-moz-placeholder {
		color:$color;
		opacity: 1;
	}
	&::-moz-placeholder {
		color:$color;
		opacity: 1;
	}
	&:-ms-input-placeholder {
		color:$color;
	}
}

@mixin transition($time:.3, $prop:all) {
	-moz-transition: $prop #{$time}s ease-in-out;
	-webkit-transition: $prop #{$time}s ease-in-out;
	transition: $prop #{$time}s ease-in-out;
}

@mixin max_screen($break-point) {
	@media screen and (max-width: $break-point) {
		@content;
	}
}

@mixin min_screen($break-point) {
	@media screen and (min-width: $break-point) {
		@content;
	}
}

@mixin is($device) {
	@at-root.is_$device & {
		@content;
	}
}