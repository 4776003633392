@charset "UTF-8";
@import url(//fonts.googleapis.com/css?family=Open+Sans:400,400italic,600,600italic,700,700italic);
@font-face {
  font-family: 'ZaneYuGothicM';
  font-weight: normal;
  src: local("YuGothic-Medium"), local("Yu Gothic Medium"), local("YuGothic"), local("YuGothic-Regular"); }

@font-face {
  font-family: 'ZaneYuGothicM';
  font-weight: bold;
  src: local("YuGothic-Bold"), local("Yu Gothic"), local("YuGothic"); }

/* ===============================================

	RESET
  
=============================================== */
html, body, div, span, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
abbr, address, cite, code,
del, dfn, em, img, ins, kbd, q, samp,
small, strong, sub, sup, var,
b, i,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, figcaption, figure,
footer, header, hgroup, menu, nav, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent; }

body {
  line-height: 1; }

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

h1, h2, h3, h4, h5 {
  line-height: 1;
  font-size: 100%;
  font-weight: normal; }

img {
  border: none;
  vertical-align: bottom; }

ul, ol, li, dl, dt, dd {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

a {
  margin: 0;
  padding: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent; }

/* change colours to suit your needs */
ins {
  background-color: #ff9;
  color: #000;
  text-decoration: none; }

/* change colours to suit your needs */
mark {
  background-color: #ff9;
  color: #000;
  font-style: italic;
  font-weight: bold; }

del {
  text-decoration: line-through; }

abbr[title], dfn[title] {
  border-bottom: 1px dotted;
  cursor: help; }

table {
  border-collapse: collapse;
  border-spacing: 0;
  font-weight: normal; }

caption, th, td {
  text-align: left; }

/* change border colour to suit your needs */
hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #cccccc;
  margin: 1em 0;
  padding: 0; }

input, textarea, select {
  vertical-align: middle;
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit; }

address {
  font-style: normal; }

/* ===============================================

	FONT SIZE
  
=============================================== */
body {
  font-size: 13px;
  line-height: 1.5;
  -webkit-text-size-adjust: none; }

select, input, textarea, button {
  font-size: 13px;
  -webkit-text-size-adjust: none; }

table {
  font-size: inherit;
  font: 100%; }

sup {
  font-size: 77%;
  vertical-align: top; }

/* ===============================================

	FONT FAMILY
  
=============================================== */
body {
  font-family: "游ゴシック体", YuGothic, "游ゴシック Medium", ZaneYuGothicM,"ヒラギノ角ゴ Pro W3","Hiragino Kaku Gothic Pro","メイリオ", Meiryo, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  text-rendering: optimizeLegibility; }

@media all and (-ms-high-contrast: none) {
  body {
    font-family: "ヒラギノ角ゴ Pro W3","Hiragino Kaku Gothic Pro","メイリオ",Meiryo,"ＭＳ Ｐゴシック","MS PGothic",sans-serif; } }

.mt0 {
  margin-top: 0px !important; }

.mb0 {
  margin-bottom: 0px !important; }

.ml0 {
  margin-left: 0px !important; }

.mr0 {
  margin-right: 0px !important; }

.mt1 {
  margin-top: 1px !important; }

.mb1 {
  margin-bottom: 1px !important; }

.ml1 {
  margin-left: 1px !important; }

.mr1 {
  margin-right: 1px !important; }

.mt2 {
  margin-top: 2px !important; }

.mb2 {
  margin-bottom: 2px !important; }

.ml2 {
  margin-left: 2px !important; }

.mr2 {
  margin-right: 2px !important; }

.mt3 {
  margin-top: 3px !important; }

.mb3 {
  margin-bottom: 3px !important; }

.ml3 {
  margin-left: 3px !important; }

.mr3 {
  margin-right: 3px !important; }

.mt4 {
  margin-top: 4px !important; }

.mb4 {
  margin-bottom: 4px !important; }

.ml4 {
  margin-left: 4px !important; }

.mr4 {
  margin-right: 4px !important; }

.mt5 {
  margin-top: 5px !important; }

.mb5 {
  margin-bottom: 5px !important; }

.ml5 {
  margin-left: 5px !important; }

.mr5 {
  margin-right: 5px !important; }

.mt6 {
  margin-top: 6px !important; }

.mb6 {
  margin-bottom: 6px !important; }

.ml6 {
  margin-left: 6px !important; }

.mr6 {
  margin-right: 6px !important; }

.mt7 {
  margin-top: 7px !important; }

.mb7 {
  margin-bottom: 7px !important; }

.ml7 {
  margin-left: 7px !important; }

.mr7 {
  margin-right: 7px !important; }

.mt8 {
  margin-top: 8px !important; }

.mb8 {
  margin-bottom: 8px !important; }

.ml8 {
  margin-left: 8px !important; }

.mr8 {
  margin-right: 8px !important; }

.mt9 {
  margin-top: 9px !important; }

.mb9 {
  margin-bottom: 9px !important; }

.ml9 {
  margin-left: 9px !important; }

.mr9 {
  margin-right: 9px !important; }

.mt10 {
  margin-top: 10px !important; }

.mb10 {
  margin-bottom: 10px !important; }

.ml10 {
  margin-left: 10px !important; }

.mr10 {
  margin-right: 10px !important; }

.mt11 {
  margin-top: 11px !important; }

.mb11 {
  margin-bottom: 11px !important; }

.ml11 {
  margin-left: 11px !important; }

.mr11 {
  margin-right: 11px !important; }

.mt12 {
  margin-top: 12px !important; }

.mb12 {
  margin-bottom: 12px !important; }

.ml12 {
  margin-left: 12px !important; }

.mr12 {
  margin-right: 12px !important; }

.mt13 {
  margin-top: 13px !important; }

.mb13 {
  margin-bottom: 13px !important; }

.ml13 {
  margin-left: 13px !important; }

.mr13 {
  margin-right: 13px !important; }

.mt14 {
  margin-top: 14px !important; }

.mb14 {
  margin-bottom: 14px !important; }

.ml14 {
  margin-left: 14px !important; }

.mr14 {
  margin-right: 14px !important; }

.mt15 {
  margin-top: 15px !important; }

.mb15 {
  margin-bottom: 15px !important; }

.ml15 {
  margin-left: 15px !important; }

.mr15 {
  margin-right: 15px !important; }

.mt16 {
  margin-top: 16px !important; }

.mb16 {
  margin-bottom: 16px !important; }

.ml16 {
  margin-left: 16px !important; }

.mr16 {
  margin-right: 16px !important; }

.mt17 {
  margin-top: 17px !important; }

.mb17 {
  margin-bottom: 17px !important; }

.ml17 {
  margin-left: 17px !important; }

.mr17 {
  margin-right: 17px !important; }

.mt18 {
  margin-top: 18px !important; }

.mb18 {
  margin-bottom: 18px !important; }

.ml18 {
  margin-left: 18px !important; }

.mr18 {
  margin-right: 18px !important; }

.mt19 {
  margin-top: 19px !important; }

.mb19 {
  margin-bottom: 19px !important; }

.ml19 {
  margin-left: 19px !important; }

.mr19 {
  margin-right: 19px !important; }

.mt20 {
  margin-top: 20px !important; }

.mb20 {
  margin-bottom: 20px !important; }

.ml20 {
  margin-left: 20px !important; }

.mr20 {
  margin-right: 20px !important; }

.mt21 {
  margin-top: 21px !important; }

.mb21 {
  margin-bottom: 21px !important; }

.ml21 {
  margin-left: 21px !important; }

.mr21 {
  margin-right: 21px !important; }

.mt22 {
  margin-top: 22px !important; }

.mb22 {
  margin-bottom: 22px !important; }

.ml22 {
  margin-left: 22px !important; }

.mr22 {
  margin-right: 22px !important; }

.mt23 {
  margin-top: 23px !important; }

.mb23 {
  margin-bottom: 23px !important; }

.ml23 {
  margin-left: 23px !important; }

.mr23 {
  margin-right: 23px !important; }

.mt24 {
  margin-top: 24px !important; }

.mb24 {
  margin-bottom: 24px !important; }

.ml24 {
  margin-left: 24px !important; }

.mr24 {
  margin-right: 24px !important; }

.mt25 {
  margin-top: 25px !important; }

.mb25 {
  margin-bottom: 25px !important; }

.ml25 {
  margin-left: 25px !important; }

.mr25 {
  margin-right: 25px !important; }

.mt26 {
  margin-top: 26px !important; }

.mb26 {
  margin-bottom: 26px !important; }

.ml26 {
  margin-left: 26px !important; }

.mr26 {
  margin-right: 26px !important; }

.mt27 {
  margin-top: 27px !important; }

.mb27 {
  margin-bottom: 27px !important; }

.ml27 {
  margin-left: 27px !important; }

.mr27 {
  margin-right: 27px !important; }

.mt28 {
  margin-top: 28px !important; }

.mb28 {
  margin-bottom: 28px !important; }

.ml28 {
  margin-left: 28px !important; }

.mr28 {
  margin-right: 28px !important; }

.mt29 {
  margin-top: 29px !important; }

.mb29 {
  margin-bottom: 29px !important; }

.ml29 {
  margin-left: 29px !important; }

.mr29 {
  margin-right: 29px !important; }

.mt30 {
  margin-top: 30px !important; }

.mb30 {
  margin-bottom: 30px !important; }

.ml30 {
  margin-left: 30px !important; }

.mr30 {
  margin-right: 30px !important; }

.mt31 {
  margin-top: 31px !important; }

.mb31 {
  margin-bottom: 31px !important; }

.ml31 {
  margin-left: 31px !important; }

.mr31 {
  margin-right: 31px !important; }

.mt32 {
  margin-top: 32px !important; }

.mb32 {
  margin-bottom: 32px !important; }

.ml32 {
  margin-left: 32px !important; }

.mr32 {
  margin-right: 32px !important; }

.mt33 {
  margin-top: 33px !important; }

.mb33 {
  margin-bottom: 33px !important; }

.ml33 {
  margin-left: 33px !important; }

.mr33 {
  margin-right: 33px !important; }

.mt34 {
  margin-top: 34px !important; }

.mb34 {
  margin-bottom: 34px !important; }

.ml34 {
  margin-left: 34px !important; }

.mr34 {
  margin-right: 34px !important; }

.mt35 {
  margin-top: 35px !important; }

.mb35 {
  margin-bottom: 35px !important; }

.ml35 {
  margin-left: 35px !important; }

.mr35 {
  margin-right: 35px !important; }

.mt36 {
  margin-top: 36px !important; }

.mb36 {
  margin-bottom: 36px !important; }

.ml36 {
  margin-left: 36px !important; }

.mr36 {
  margin-right: 36px !important; }

.mt37 {
  margin-top: 37px !important; }

.mb37 {
  margin-bottom: 37px !important; }

.ml37 {
  margin-left: 37px !important; }

.mr37 {
  margin-right: 37px !important; }

.mt38 {
  margin-top: 38px !important; }

.mb38 {
  margin-bottom: 38px !important; }

.ml38 {
  margin-left: 38px !important; }

.mr38 {
  margin-right: 38px !important; }

.mt39 {
  margin-top: 39px !important; }

.mb39 {
  margin-bottom: 39px !important; }

.ml39 {
  margin-left: 39px !important; }

.mr39 {
  margin-right: 39px !important; }

.mt40 {
  margin-top: 40px !important; }

.mb40 {
  margin-bottom: 40px !important; }

.ml40 {
  margin-left: 40px !important; }

.mr40 {
  margin-right: 40px !important; }

.mt41 {
  margin-top: 41px !important; }

.mb41 {
  margin-bottom: 41px !important; }

.ml41 {
  margin-left: 41px !important; }

.mr41 {
  margin-right: 41px !important; }

.mt42 {
  margin-top: 42px !important; }

.mb42 {
  margin-bottom: 42px !important; }

.ml42 {
  margin-left: 42px !important; }

.mr42 {
  margin-right: 42px !important; }

.mt43 {
  margin-top: 43px !important; }

.mb43 {
  margin-bottom: 43px !important; }

.ml43 {
  margin-left: 43px !important; }

.mr43 {
  margin-right: 43px !important; }

.mt44 {
  margin-top: 44px !important; }

.mb44 {
  margin-bottom: 44px !important; }

.ml44 {
  margin-left: 44px !important; }

.mr44 {
  margin-right: 44px !important; }

.mt45 {
  margin-top: 45px !important; }

.mb45 {
  margin-bottom: 45px !important; }

.ml45 {
  margin-left: 45px !important; }

.mr45 {
  margin-right: 45px !important; }

.mt46 {
  margin-top: 46px !important; }

.mb46 {
  margin-bottom: 46px !important; }

.ml46 {
  margin-left: 46px !important; }

.mr46 {
  margin-right: 46px !important; }

.mt47 {
  margin-top: 47px !important; }

.mb47 {
  margin-bottom: 47px !important; }

.ml47 {
  margin-left: 47px !important; }

.mr47 {
  margin-right: 47px !important; }

.mt48 {
  margin-top: 48px !important; }

.mb48 {
  margin-bottom: 48px !important; }

.ml48 {
  margin-left: 48px !important; }

.mr48 {
  margin-right: 48px !important; }

.mt49 {
  margin-top: 49px !important; }

.mb49 {
  margin-bottom: 49px !important; }

.ml49 {
  margin-left: 49px !important; }

.mr49 {
  margin-right: 49px !important; }

.mt50 {
  margin-top: 50px !important; }

.mb50 {
  margin-bottom: 50px !important; }

.ml50 {
  margin-left: 50px !important; }

.mr50 {
  margin-right: 50px !important; }

/* float */
.float_r {
  float: right !important; }

.float_l {
  float: left !important; }

.float_none {
  float: none !important; }

/* text */
.txt_r {
  text-align: right !important; }

.txt_l {
  text-align: left !important; }

.txt_c {
  text-align: center !important; }

/* hover */
.on-all a {
  opacity: 1;
  -moz-transition: opacity 0.2s ease-in-out;
  -webkit-transition: opacity 0.2s ease-in-out;
  transition: opacity 0.2s ease-in-out; }

.on-all a:hover {
  opacity: .7; }

.on {
  opacity: 1;
  -moz-transition: opacity 0.2s ease-in-out;
  -webkit-transition: opacity 0.2s ease-in-out;
  transition: opacity 0.2s ease-in-out; }

.on:hover {
  opacity: .7; }

/* clearfix*/
.cf:before,
.cf:after {
  content: "";
  display: block;
  overflow: hidden; }

.cf:after {
  clear: both; }

/* pc-sp */
.switch_sp {
  display: none !important; }
  @media screen and (max-width: 767px) {
    .switch_sp {
      display: block !important;
      margin: 0 auto; } }

.switch_pc {
  display: block !important;
  margin: 0 auto; }
  @media screen and (max-width: 767px) {
    .switch_pc {
      display: none !important; } }

body {
  width: 100%;
  background: #fff;
  font-size: 15px;
  color: #222;
  text-align: center;
  letter-spacing: 0.06em; }
  @media screen and (max-width: 767px) {
    body {
      padding-top: 50px; } }

img {
  max-width: 100%;
  height: auto; }

.global-header {
  width: 100%;
  padding: 0 20px;
  box-sizing: border-box;
  height: 188px;
  margin: 0 auto;
  position: relative;
  z-index: 1000;
  border-bottom: 1px solid #ddd; }
  .index .global-header {
    border: none; }
  .global-header_logo {
    padding: 50px 0 0;
    margin-bottom: 50px; }
  @media screen and (max-width: 767px) {
    .global-header {
      height: 50px;
      position: fixed;
      top: 0;
      left: 0;
      background: #fff; }
      .global-header_logo {
        width: 130px;
        padding: 0;
        position: absolute;
        top: 15px;
        left: 10px; } }
  .global-header_navi {
    width: 100%;
    padding-left: 10px; }
    .global-header_navi_open-btn {
      display: none; }
    .global-header_navi_header {
      display: none; }
    .global-header_navi_sub {
      display: none; }
    @media screen and (max-width: 767px) {
      .global-header_navi {
        position: fixed;
        top: 0;
        right: -100%;
        background: #fff;
        padding: 0;
        height: 100%;
        padding-bottom: 45px;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
        -moz-transition: all 0.2s ease-in-out;
        -webkit-transition: all 0.2s ease-in-out;
        transition: all 0.2s ease-in-out;
        box-sizing: border-box; }
        .global-header_navi.show {
          right: 0; }
        .global-header_navi_header {
          width: 100%;
          height: 50px;
          border-bottom: 1px solid #ddd;
          position: relative;
          display: block; }
          .global-header_navi_header_ttl {
            font-size: 16px;
            font-weight: bold;
            color: #33201f;
            position: absolute;
            top: 18px;
            left: 10px;
            line-height: 1;
            letter-spacing: 0.12em; }
        .global-header_navi_open-btn {
          width: 20px;
          height: 16px;
          position: absolute;
          top: 17px;
          right: 15px;
          cursor: pointer;
          box-sizing: border-box;
          display: block; }
          .global-header_navi_open-btn span {
            content: "";
            width: 20px;
            height: 2px;
            background: #33201f;
            position: absolute;
            top: 7px;
            left: 0; }
          .global-header_navi_open-btn:before {
            content: "";
            width: 20px;
            height: 2px;
            background: #33201f;
            position: absolute;
            top: 0;
            left: 0; }
          .global-header_navi_open-btn:after {
            content: "";
            width: 20px;
            height: 2px;
            background: #33201f;
            position: absolute;
            bottom: 0;
            left: 0; }
        .global-header_navi_close-btn {
          width: 20px;
          height: 16px;
          position: absolute;
          top: 17px;
          right: 15px;
          cursor: pointer;
          box-sizing: border-box; }
          .global-header_navi_close-btn:before {
            content: "";
            width: 20px;
            height: 2px;
            background: #33201f;
            position: absolute;
            top: 7px;
            left: 0;
            transform: rotate(45deg); }
          .global-header_navi_close-btn:after {
            content: "";
            width: 20px;
            height: 2px;
            background: #33201f;
            position: absolute;
            bottom: 7px;
            left: 0;
            transform: rotate(-45deg); }
        .global-header_navi_main {
          margin-bottom: 42px; }
        .global-header_navi_sub {
          width: 100%;
          display: block;
          text-align: left;
          padding-bottom: 43px; }
          .global-header_navi_sub_ttl {
            font-size: 15px;
            font-weight: bold;
            padding-left: 10px;
            margin-bottom: 9px; }
          .global-header_navi_sub_list {
            width: 100%;
            overflow: hidden;
            border-top: 1px solid #ddd; }
            .global-header_navi_sub_list li {
              width: 50%;
              float: left;
              border: 1px solid #ddd;
              border-width: 0 1px 1px 0;
              box-sizing: border-box;
              font-size: 14px; }
              .global-header_navi_sub_list li a,
              .global-header_navi_sub_list li a:link,
              .global-header_navi_sub_list li a:visited {
                color: #33201f;
                text-decoration: none;
                -moz-transition: color 0.2s ease-in-out;
                -webkit-transition: color 0.2s ease-in-out;
                transition: color 0.2s ease-in-out; } }
        @media screen and (max-width: 767px) and (min-width: 768px) {
          .global-header_navi_sub_list li a:hover {
            color: #bdb198; } }
    @media screen and (max-width: 767px) {
              .global-header_navi_sub_list li:nth-child(2n) {
                border-right: none; }
              .global-header_navi_sub_list li a {
                display: flex;
                height: 50px;
                line-height: 1.2;
                padding: 0 10px;
                align-items: center;
                vertical-align: middle; } }
    .global-header_navi_item {
      display: inline-block;
      position: relative;
      margin-right: 68px;
      font-weight: bold;
      font-size: 16px; }
      .global-header_navi_item a,
      .global-header_navi_item a:link,
      .global-header_navi_item a:visited {
        color: #33201f;
        text-decoration: none;
        -moz-transition: color 0.2s ease-in-out;
        -webkit-transition: color 0.2s ease-in-out;
        transition: color 0.2s ease-in-out; }
      @media screen and (min-width: 768px) {
        .global-header_navi_item a:hover {
          color: #bdb198; } }
      @media screen and (min-width: 768px) {
        .global-header_navi_item:hover > a {
          color: #bdb198; } }
      .global-header_navi_item:last-child {
        margin-right: 0; }
      .global-header_navi_item > a {
        display: inline-block;
        padding: 10px 0; }
      .global-header_navi_item:before {
        content: "";
        position: absolute;
        background-repeat: no-repeat;
        background-size: 100% auto; }
      @media screen and (max-width: 999px) {
        .global-header_navi_item {
          font-size: 14px;
          margin-right: 48px; }
          .global-header_navi_item:last-child {
            margin-right: 0; }
          .global-header_navi_item:before {
            margin-top: -1px; } }
      @media screen and (max-width: 767px) {
        .global-header_navi_item {
          display: block;
          border-bottom: 1px solid #ddd;
          margin: 0;
          text-align: left; }
          .global-header_navi_item a {
            display: block;
            width: 100%;
            height: 50px;
            padding: 0 0 0 40px;
            line-height: 50px;
            box-sizing: border-box; }
          .global-header_navi_item:after {
            content: "";
            width: 19px;
            height: 19px;
            background: url("/wp-content/themes/blog/assets/img/common/ico_navi_open.png") no-repeat;
            background-size: 100% auto;
            position: absolute;
            top: 15px;
            right: 15px; }
          .global-header_navi_item.open:after {
            background-image: url("/wp-content/themes/blog/assets/img/common/ico_navi_close.png"); }
          .global-header_navi_item.open .inner_navi {
            max-height: 500px; } }
      .global-header_navi_item:nth-child(1):before {
        width: 16px;
        height: 14px;
        top: 14px;
        left: -22px;
        background-image: url("/wp-content/themes/blog/assets/img/common/ico_navi1.png"); }
      .global-header_navi_item:nth-child(2):before {
        width: 23px;
        height: 12px;
        top: 16px;
        left: -28px;
        background-image: url("/wp-content/themes/blog/assets/img/common/ico_navi2.png"); }
      .global-header_navi_item:nth-child(3):before {
        width: 17px;
        height: 17px;
        top: 13px;
        left: -24px;
        background-image: url("/wp-content/themes/blog/assets/img/common/ico_navi3.png"); }
      .global-header_navi_item:nth-child(4):before {
        width: 12px;
        height: 17px;
        top: 11px;
        left: -20px;
        background-image: url("/wp-content/themes/blog/assets/img/common/ico_navi4.png"); }
      .global-header_navi_item:nth-child(5):before {
        width: 18px;
        height: 12px;
        top: 15px;
        left: -24px;
        background-image: url("/wp-content/themes/blog/assets/img/common/ico_navi5.png"); }
      .global-header_navi_item:nth-child(6):before {
        width: 16px;
        height: 14px;
        top: 15px;
        left: -24px;
        background-image: url("/wp-content/themes/blog/assets/img/common/ico_navi6.png"); }
      .global-header_navi_item:nth-child(7):before {
        width: 14px;
        height: 15px;
        top: 14px;
        left: -19px;
        background-image: url("/wp-content/themes/blog/assets/img/common/ico_navi7.png"); }
      @media screen and (max-width: 767px) {
        .global-header_navi_item:nth-child(1):before {
          width: 16px;
          height: 14px;
          top: 18px;
          left: 17px; }
        .global-header_navi_item:nth-child(2):before {
          width: 23px;
          height: 12px;
          top: 20px;
          left: 12px; }
        .global-header_navi_item:nth-child(3):before {
          width: 17px;
          height: 17px;
          top: 16px;
          left: 15px; }
        .global-header_navi_item:nth-child(4):before {
          width: 12px;
          height: 17px;
          top: 14px;
          left: 18px; }
        .global-header_navi_item:nth-child(5):before {
          width: 18px;
          height: 12px;
          top: 19px;
          left: 15px; }
        .global-header_navi_item:nth-child(6):before {
          width: 16px;
          height: 14px;
          top: 18px;
          left: 16px; }
        .global-header_navi_item:nth-child(7):before {
          width: 14px;
          height: 15px;
          top: 17px;
          left: 17px; } }
      .global-header_navi_item .inner_navi {
        width: 200px;
        position: absolute;
        top: 40px;
        left: -40px;
        background: #fff;
        padding: 15px 0 15px 40px;
        text-align: left;
        box-sizing: border-box;
        opacity: 0;
        visibility: hidden;
        margin-top: -10px;
        -moz-transition: all 0.25s ease-in-out;
        -webkit-transition: all 0.25s ease-in-out;
        transition: all 0.25s ease-in-out; }
        .global-header_navi_item .inner_navi li {
          padding: 15px 0;
          line-height: 1; }
        @media screen and (max-width: 999px) {
          .global-header_navi_item .inner_navi.last {
            left: auto;
            right: 0; } }
        @media screen and (max-width: 767px) {
          .global-header_navi_item .inner_navi {
            width: 100%;
            max-height: 0;
            background: #f4f4f4;
            position: static;
            padding: 0;
            opacity: 1;
            visibility: visible;
            margin: 0;
            overflow: hidden;
            -moz-transition: max-height 0.3s ease-in-out;
            -webkit-transition: max-height 0.3s ease-in-out;
            transition: max-height 0.3s ease-in-out; }
            .global-header_navi_item .inner_navi li {
              height: 50px;
              border-top: 1px solid #ddd;
              padding: 0; } }
      @media screen and (min-width: 768px) {
        .global-header_navi_item:hover .inner_navi {
          opacity: 1;
          visibility: visible;
          margin-top: 0; } }

.blog-catch {
  width: 100%;
  background-size: cover;
  background-position: center center; }
  .blog-catch a,
  .blog-catch a:link,
  .blog-catch a:visited {
    color: #33201f;
    text-decoration: none;
    -moz-transition: color 0.2s ease-in-out;
    -webkit-transition: color 0.2s ease-in-out;
    transition: color 0.2s ease-in-out; }
  @media screen and (min-width: 768px) {
    .blog-catch a:hover {
      color: #bdb198; } }
  .blog-catch a:visited {
    color: inherit; }
  .blog-catch_inner {
    width: 100%;
    max-width: 1040px;
    padding: 0 20px;
    box-sizing: border-box;
    height: 550px;
    margin: 0 auto;
    position: relative; }
    @media screen and (max-width: 767px) {
      .blog-catch_inner {
        height: 228px; } }
  .blog-catch_ttl {
    font-weight: bold;
    text-align: left;
    position: absolute;
    bottom: 47px;
    left: 0; }
    .blog-catch_ttl span {
      display: inline-block;
      background: #fff;
      line-height: 1;
      font-size: 26px;
      padding: 10px 13px 8px;
      margin-right: 20px;
      border-radius: 2px;
      margin-bottom: 3px; }
    @media screen and (max-width: 767px) {
      .blog-catch_ttl {
        bottom: 20px;
        left: 10px; }
        .blog-catch_ttl span {
          padding: 6px 9px 5px;
          font-size: 17px; } }

.contents {
  width: 100%;
  max-width: 1040px;
  padding: 70px 20px 0;
  box-sizing: border-box;
  margin: 0 auto;
  overflow: hidden;
  text-align: left; }
  .contents.archive_page, .contents.category_page, .contents.single_page {
    padding-top: 60px; }
  .contents .main-col {
    width: 72%;
    float: left; }
  .contents .side-col {
    width: 23%;
    float: right; }
    .contents .side-col_ttl {
      font-weight: bold;
      color: #33201f;
      font-size: 16px;
      padding-bottom: 10px;
      border-bottom: 1px solid #ddd;
      margin-bottom: 16px; }
  @media screen and (max-width: 767px) {
    .contents {
      padding: 30px 0 0; }
      .contents .archive_page, .contents.category_page, .contents.single_page {
        padding-top: 30px; }
      .contents .main-col {
        width: 100%;
        float: none;
        padding: 0 10px;
        box-sizing: border-box;
        margin-bottom: 50px; }
      .contents .side-col {
        width: 100%;
        float: none;
        padding: 0 10px;
        box-sizing: border-box; }
        .contents .side-col_ttl {
          color: #33201f;
          font-size: 17px;
          padding: 13px 0 12px;
          border-bottom: none;
          margin-bottom: 22px;
          background: #eee;
          text-align: center;
          border-radius: 2px; } }

.article-list_item {
  width: 100%;
  overflow: hidden;
  padding-bottom: 40px;
  margin-bottom: 40px;
  border-bottom: 1px solid #ddd; }
  .article-list_item:last-child {
    border-bottom: none;
    margin-bottom: 0;
    padding-bottom: 0; }
  .article-list_item .eyecatch {
    width: 29%;
    padding-top: 29%;
    float: left;
    border-radius: 2px;
    overflow: hidden;
    position: relative; }
    .article-list_item .eyecatch img {
      width: 100%;
      backface-visibility: hidden;
      position: absolute;
      top: 0;
      left: 0; }
  @media screen and (max-width: 767px) {
    .article-list_item {
      padding-bottom: 25px;
      margin-bottom: 25px; }
      .article-list_item .eyecatch {
        border-radius: 4px; } }
  .article-list_item .detail {
    width: 66%;
    float: right; }
    .article-list_item .detail a,
    .article-list_item .detail a:link,
    .article-list_item .detail a:visited {
      color: #33201f;
      text-decoration: none;
      -moz-transition: color 0.2s ease-in-out;
      -webkit-transition: color 0.2s ease-in-out;
      transition: color 0.2s ease-in-out; }
    @media screen and (min-width: 768px) {
      .article-list_item .detail a:hover {
        color: #bdb198; } }
    .article-list_item .detail .ttl {
      font-size: 20px;
      font-weight: bold;
      letter-spacing: 0.08em;
      line-height: 1.5; }
      .article-list_item .detail .ttl a {
        display: block; }
    .article-list_item .detail .meta {
      font-size: 13px;
      color: #a7997c;
      padding: 10px 0 18px; }
      .article-list_item .detail .meta_cat {
        display: inline-block;
        margin-left: 26px; }
        .article-list_item .detail .meta_cat li {
          display: inline; }
          .article-list_item .detail .meta_cat li:after {
            content: " / "; }
          .article-list_item .detail .meta_cat li:last-child:after {
            display: none; }
      .article-list_item .detail .meta a {
        color: inherit; }
        .article-list_item .detail .meta a:hover {
          color: #33201f; }
    .article-list_item .detail .excerpt {
      line-height: 1.6;
      letter-spacing: 0.1em;
      text-align: left; }
      .article-list_item .detail .excerpt p, .article-list_item .detail .excerpt a {
        display: inline; }
    @media screen and (max-width: 767px) {
      .article-list_item .detail .ttl {
        font-size: 17px; }
      .article-list_item .detail .meta {
        padding: 4px 0 0; }
        .article-list_item .detail .meta_cat {
          display: none; }
      .article-list_item .detail .excerpt {
        display: none; } }

.pager {
  width: 100%;
  text-align: center;
  padding-top: 80px; }
  .pager a,
  .pager a:link,
  .pager a:visited {
    color: #33201f;
    text-decoration: none;
    -moz-transition: color 0.2s ease-in-out;
    -webkit-transition: color 0.2s ease-in-out;
    transition: color 0.2s ease-in-out; }
  @media screen and (min-width: 768px) {
    .pager a:hover {
      color: #bdb198; } }
  .pager a, .pager span {
    display: inline-block;
    padding: 3px 8px;
    border: 1px solid #33201f;
    margin: 0 3px;
    text-align: center; }
    .pager a.current, .pager span.current {
      font-weight: bold;
      background: #33201f;
      color: #fff; }
  @media screen and (max-width: 767px) {
    .pager {
      padding-top: 40px;
      font-size: 13px; }
      .pager a, .pager span {
        display: inline-block;
        padding: 5px 10px 4px;
        border: 1px solid #33201f;
        margin: 0 3px;
        text-align: center; }
        .pager a.pages, .pager span.pages {
          display: none; }
        .pager a.nextpostslink, .pager a.previouspostslink, .pager span.nextpostslink, .pager span.previouspostslink {
          padding: 5px 5px 4px;
          letter-spacing: 0.02em; } }

.about-btn {
  width: 100%;
  margin-bottom: 45px; }
  .about-btn a,
  .about-btn a:link,
  .about-btn a:visited {
    color: #fff;
    background-color: #bdb198;
    text-decoration: none;
    opacity: 1;
    -moz-transition: opacity 0.2s ease-in-out;
    -webkit-transition: opacity 0.2s ease-in-out;
    transition: opacity 0.2s ease-in-out; }
  @media screen and (min-width: 768px) {
    .about-btn a:hover {
      opacity: 0.7; } }
  .about-btn a {
    display: block;
    padding: 20px 30px;
    line-height: 1;
    font-weight: bold;
    font-size: 16px;
    letter-spacing: 0.02em;
    border-radius: 2px; }
    .about-btn a span {
      padding-right: 17px;
      background: url("/wp-content/themes/blog/assets/img/common/ico_arw-white.png") no-repeat right center;
      background-size: 6px auto; }
  @media screen and (max-width: 767px) {
    .about-btn {
      display: none; } }

.side-col_ranking {
  margin-bottom: 54px; }
  .side-col_ranking .wpp-list li {
    list-style: decimal;
    font-weight: bold;
    margin-left: 1.5em;
    margin-bottom: 15px;
    line-height: 1.4; }
    .side-col_ranking .wpp-list li a,
    .side-col_ranking .wpp-list li a:link,
    .side-col_ranking .wpp-list li a:visited {
      color: #33201f;
      text-decoration: none;
      -moz-transition: color 0.2s ease-in-out;
      -webkit-transition: color 0.2s ease-in-out;
      transition: color 0.2s ease-in-out; }
    @media screen and (min-width: 768px) {
      .side-col_ranking .wpp-list li a:hover {
        color: #bdb198; } }
    .side-col_ranking .wpp-list li:last-child {
      margin-bottom: 0; }
    .side-col_ranking .wpp-list li a {
      font-weight: normal; }
  @media screen and (max-width: 767px) {
    .side-col_ranking {
      margin-bottom: 50px; } }

.side-col_category {
  margin-bottom: 54px; }
  .side-col_category:last-of-type {
    margin-bottom: 60px; }
  .side-col_category_list li {
    margin-bottom: 13px;
    line-height: 1.4;
    padding-left: 13px;
    margin-left: 7px;
    background: url("/wp-content/themes/blog/assets/img/common/ico_arw-s.png") no-repeat left center; }
    .side-col_category_list li a,
    .side-col_category_list li a:link,
    .side-col_category_list li a:visited {
      color: #33201f;
      text-decoration: none;
      -moz-transition: color 0.2s ease-in-out;
      -webkit-transition: color 0.2s ease-in-out;
      transition: color 0.2s ease-in-out; }
    @media screen and (min-width: 768px) {
      .side-col_category_list li a:hover {
        color: #bdb198; } }
    .side-col_category_list li:last-child {
      margin-bottom: 0; }
  @media screen and (max-width: 767px) {
    .side-col_category {
      display: none; } }

.search-form {
  width: 100%;
  position: relative; }
  .search-form .search-field {
    width: 100%;
    height: 34px;
    background: #eee;
    border-radius: 2px;
    line-height: 34px;
    border: none;
    padding: 0 33px 0 14px; }
  .search-form .search-submit {
    position: absolute;
    top: 9px;
    right: 10px;
    width: 15px;
    height: 16px;
    background: url("/wp-content/themes/blog/assets/img/common/ico_search.png") no-repeat;
    background-size: 100% auto;
    border: none;
    font-family: Avenir, "Open Sans", -apple-system, BlinkMacSystemFont, "游ゴシック体", YuGothic, "游ゴシック Medium", ZaneYuGothicM, Meiryo, sans-serif; }
  @media screen and (max-width: 767px) {
    .search-form {
      padding: 0 10px;
      box-sizing: border-box; }
      .search-form .search-field {
        height: 45px;
        line-height: 45px;
        font-size: 16px; }
      .search-form .search-submit {
        top: 12px;
        right: 20px;
        width: 22px;
        height: 23px; } }

.bnr {
  margin-top: 30px; }
  .bnr img {
    width: 100%; }
  @media screen and (max-width: 767px) {
    .bnr {
      padding: 0 10px;
      margin-top: 0;
      margin-bottom: 25px; }
      .bnr:last-child {
        margin-bottom: 0; } }

.global-footer {
  width: 100%;
  text-align: center;
  font-family: "Helvetica Neue", helvetica, arial;
  padding-top: 100px; }
  .global-footer_sns {
    margin-bottom: 24px;
    color: #33201f; }
    .global-footer_sns dt {
      font-size: 16px;
      letter-spacing: 0.2em;
      display: inline-block;
      position: relative;
      top: -2px; }
    .global-footer_sns dd {
      display: inline-block;
      margin-left: 18px; }
  .global-footer_copyright {
    color: #fff;
    font-size: 10px;
    letter-spacing: 0.2em;
    background: #33201f;
    padding: 12px 0 10px;
    line-height: 1; }
  @media screen and (max-width: 767px) {
    .global-footer {
      padding-top: 50px; }
      .global-footer_sns {
        margin-bottom: 20px; }
        .global-footer_sns dt {
          font-size: 10px; }
        .global-footer_sns dd {
          width: 25px;
          margin-left: 16px; }
      .global-footer_copyright {
        font-size: 9px;
        padding: 19px 0; } }

.entry {
  width: 100%;
  overflow: hidden;
  padding-bottom: 20px;
  margin-bottom: 30px;
  border-bottom: 1px solid #ddd; }
  .entry a,
  .entry a:link,
  .entry a:visited {
    color: #33201f;
    text-decoration: none;
    -moz-transition: color 0.2s ease-in-out;
    -webkit-transition: color 0.2s ease-in-out;
    transition: color 0.2s ease-in-out; }
  @media screen and (min-width: 768px) {
    .entry a:hover {
      color: #bdb198; } }
  .entry a,
  .entry a:link,
  .entry a:visited {
    color: #24959c; }
  @media screen and (min-width: 768px) {
    .entry a:hover {
      color: #bdb198; } }
  .entry_ttl {
    font-size: 28px;
    font-weight: bold;
    line-height: 1.5; }
  .entry_meta {
    font-size: 13px;
    color: #a7997c;
    padding: 16px 0 58px; }
    .entry_meta_cat {
      display: inline-block;
      margin-left: 26px; }
      .entry_meta_cat li {
        display: inline; }
        .entry_meta_cat li:after {
          content: " / "; }
        .entry_meta_cat li:last-child:after {
          display: none; }
    .entry_meta a {
      color: #a7997c; }
      .entry_meta a:link, .entry_meta a:visited {
        color: #a7997c; }
      .entry_meta a:hover {
        color: #33201f; }
  @media screen and (max-width: 767px) {
    .entry_ttl {
      font-size: 18px; }
    .entry_meta {
      padding: 15px 0 40px; } }
  .entry_body {
    font-size: 15px;
    letter-spacing: 0.12em;
    line-height: 1.6;
    margin-bottom: 70px; }
    .entry_body p {
      margin-bottom: 1.5em; }
    .entry_body h2 {
      font-size: 26px;
      line-height: 1.4;
      margin-bottom: 0.8em;
      font-weight: bold; }
    .entry_body h3 {
      font-size: 22px;
      line-height: 1.4;
      font-weight: bold; }
    .entry_body h4 {
      font-size: 18px;
      line-height: 1.4;
      font-weight: bold; }
    @media screen and (max-width: 767px) {
      .entry_body {
        font-size: 14px;
        margin-bottom: 35px; }
        .entry_body h2 {
          font-size: 18px; }
        .entry_body h3 {
          font-size: 16px; }
        .entry_body h4 {
          font-size: 15px; } }
    .entry_body table td,
    .entry_body table th {
      border: 1px solid #ccc;
      padding: 10px; }
    .entry_body table th {
      background: #f4f4f4;
      font-weight: bold;
      text-align: center; }
    .entry_body ul {
      margin-bottom: 1.5em; }
      .entry_body ul li {
        padding-left: 25px;
        position: relative;
        line-height: 1.4;
        margin-bottom: 10px; }
        .entry_body ul li:last-child {
          margin-bottom: 0; }
        .entry_body ul li:before {
          content: "";
          width: 6px;
          height: 6px;
          background: #a7a7a7;
          border-radius: 50%;
          display: block;
          position: absolute;
          top: 8px;
          left: 9px; }
  .entry_staff {
    width: 100%;
    border: 3px solid #ddd;
    box-sizing: border-box;
    padding: 38px 38px 30px;
    position: relative;
    margin-bottom: 60px; }
    .entry_staff_ttl {
      width: 100%;
      font-size: 16px;
      font-weight: bold;
      position: absolute;
      top: -12px;
      left: 0;
      right: 0;
      text-align: center; }
      .entry_staff_ttl span {
        display: inline-block;
        padding: 0 5px;
        background: #fff; }
    .entry_staff_photo {
      width: 120px;
      display: table-cell;
      text-align: center; }
    .entry_staff_img {
      width: 120px;
      height: 120px;
      border-radius: 50%;
      overflow: hidden;
      margin-bottom: 12px; }
    .entry_staff_name {
      font-size: 13px;
      line-height: 1; }
    .entry_staff_desc {
      display: table-cell;
      font-size: 15px;
      line-height: 1.6;
      vertical-align: middle;
      padding-left: 40px; }
    @media screen and (max-width: 767px) {
      .entry_staff {
        padding: 30px 15px 15px;
        margin-bottom: 50px; }
        .entry_staff_photo {
          width: 100%;
          display: block;
          margin-bottom: 20px; }
        .entry_staff_img {
          width: 90px;
          height: 90px;
          margin: 0 auto 12px; }
        .entry_staff_name {
          font-size: 14px; }
        .entry_staff_desc {
          display: block;
          font-size: 14px;
          padding: 0; } }
  .entry_sns {
    width: 100%; }
    .entry_sns_item {
      overflow: hidden;
      display: inline-block;
      line-height: 1;
      vertical-align: top; }
    @media screen and (max-width: 767px) {
      .entry_sns {
        margin-top: -10px;
        overflow: hidden; }
        .entry_sns_item {
          margin-top: 10px;
          margin-right: 10px; } }

.single-pager {
  width: 100%;
  overflow: hidden; }
  .single-pager li {
    width: 45%;
    font-size: 13px; }
    .single-pager li a,
    .single-pager li a:link,
    .single-pager li a:visited {
      color: #33201f;
      text-decoration: none;
      -moz-transition: color 0.2s ease-in-out;
      -webkit-transition: color 0.2s ease-in-out;
      transition: color 0.2s ease-in-out; }
    @media screen and (min-width: 768px) {
      .single-pager li a:hover {
        color: #bdb198; } }
    .single-pager li.prev {
      float: left;
      text-align: left;
      padding-left: 10px;
      background: url("/wp-content/themes/blog/assets/img/common/ico_arw_left-s.png") no-repeat left center; }
    .single-pager li.next {
      float: right;
      text-align: right;
      padding-right: 10px;
      background: url("/wp-content/themes/blog/assets/img/common/ico_arw-s.png") no-repeat right center; }

.related-posts {
  padding-top: 40px; }
  .related-posts_ttl {
    width: 100%;
    background: #eee;
    font-size: 20px;
    font-weight: bold;
    padding: 0 20px;
    line-height: 57px;
    border-radius: 2px;
    margin-bottom: 40px;
    box-sizing: border-box; }
  @media screen and (max-width: 767px) {
    .related-posts_ttl {
      color: #33201f;
      font-size: 16px;
      padding: 13px 0 12px;
      margin-bottom: 22px;
      background: #eee;
      text-align: center;
      line-height: 1; } }
